import React, { lazy, Suspense, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import backgroundVideo from "./assets/video/background_small.mp4";
import backgroundImage from "./assets/images/background_small.jpg";
import Loading from "./pages/Loading";

const Logo = lazy(() => import("./pages/Logo"));
const Info = lazy(() => import("./pages/Info"));
const Contact = lazy(() => import("./pages/Contact"));
const AGB = lazy(() => import("./pages/AGB"));
const Imprint = lazy(() => import("./pages/Imprint"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Showreel = lazy(() => import("./pages/Showreel"));

function App() {
  const location = useLocation();

  const loader = document.querySelector("#html-loader");

  const hideLoader = () => {
    if (loader) loader.classList.add("loader--hide");
  };
  // useEffect(() => {
  //   if (loader) loader.classList.add("loader--hide");
  // }, []);

  return (
    <div className="App">
      <video
        id="background-video"
        autoPlay
        playsInline
        loop
        muted
        poster={backgroundImage}
        onPlaying={hideLoader}
        // onCanPlay={hideLoader}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <header>
        <div className="menu">
          <Link to="/info">Info</Link>
          <Link to="/showreel">Showreel</Link>
        </div>
        <Link to="/">
          <div className="brand">
            <p className="header">meZWEI</p>
            <span className="corporate-orange">me</span>dizin- und{" "}
            <span className="corporate-blue">me</span>dien-privatinstitut GmbH
          </div>
        </Link>
      </header>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Suspense fallback={<Loading />}>
            <Routes location={location}>
              <Route path="/" element={<Logo />} />
              <Route path="info" element={<Info />} />
              <Route path="showreel" element={<Showreel />} />
              <Route path="agb" element={<AGB />} />
              <Route path="kontakt" element={<Contact />} />
              <Route path="impressum" element={<Imprint />} />
              <Route path="datenschutz" element={<PrivacyPolicy />} />
            </Routes>
          </Suspense>
        </CSSTransition>
      </SwitchTransition>

      <footer>
        <ul>
          <li>
            <Link to={"/kontakt"}>Kontakt</Link>
          </li>
          <li>
            <Link to={"/impressum"}>Impressum</Link>
          </li>
          <li>
            <Link to={"/agb"}>AGB</Link>
          </li>
          <li>
            <Link to={"/datenschutz"}>Datenschutz</Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
